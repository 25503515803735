import styled from "styled-components"
import breakpoints from "../../styledComponents/breakpoints"

const StyledResourceHeroCard = styled.article`
  font-size: 1.6rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;

  p {
    margin: 0;
  }
`

const ResourceWrapper = styled.div`
  margin: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
  }
`

const ResourceHeader = styled.h2`
  margin: 0.8rem 0;
  color: var(--light-blue-darkest);
  a {
    text-decoration: none;
  }
`

const ResourceFigure = styled.figure`
  margin: 0;
  padding: 0;
  &:first-child {
    margin-left: -1.6rem;
    width: 100vw;
    min-width: 32rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: 0;
      width: auto;
    }
  }
`

const ResourceFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
  
`

export {
  StyledResourceHeroCard,
  ResourceWrapper,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
}
