import styled from "styled-components"
import { regular16, semibold16 } from "../../styledComponents/typography"

const StyledTertiaryBlogCard = styled.article`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  padding: 0;

  p {
    margin: 0;
    padding-bottom: 1.6rem;
    border-bottom: solid 1px var(--light-blue-mid);
  }
  h3 {
    ${semibold16}
  }
`


const ResourceHeader = styled.h3`
  color: var(--grey4);
  margin: 0;
  ${regular16}
  color: var(--light-blue-darkest);
  a {
    text-decoration: none;
  }
`

const ResourceFigure = styled.figure`
  margin: 0;
  padding: 0;
`

const ResourceFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
`

export {
  StyledTertiaryBlogCard,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
}
