import React from "react"
import {
  StyledTertiaryBlogCard,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
} from "./StyledTertiaryBlogCard"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"

const TertiaryBlogCard = props => {
  const { title, featuredImage, uri, excerpt } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }

  return (
    <StyledTertiaryBlogCard
      itemScope
      itemType="http://schema.org/Article"
    >
      <Link to={uri} itemProp="url">
        {image && image.imageData && (
          <ResourceFigure>
            <GatsbyImage
              image={image.imageData}
              alt={image.alt}
              width="100%"
              padding-bottom="0" />
            <ResourceFigureCaption>{image.description}</ResourceFigureCaption>
          </ResourceFigure>
        )}

        <ResourceHeader itemProp="headline">
          {parse(title)}
        </ResourceHeader>
        {excerpt && parse(excerpt)}
      </Link>
    </StyledTertiaryBlogCard>
  );
}

export default TertiaryBlogCard
