import React from "react"
import {
  StyledResourceHeroCard,
  ResourceWrapper,
  ResourceHeader,
  ResourceFigure,
} from "./StyledHeroBlogCard"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const HeroBlogCard = props => {
  const { title, featuredImage, uri, excerpt } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }

  return (
    <StyledResourceHeroCard
      itemScope
      itemType="http://schema.org/Article"
      className={"secondary"}
    > <Link to={uri} itemProp="url">
        {image && image.imageData && (
          <ResourceFigure>
            <GatsbyImage image={image.imageData} alt={image.alt} />
          </ResourceFigure>
        )}
        <ResourceWrapper>
          <ResourceHeader itemProp="headline">
            {parse(title)}
          </ResourceHeader>
          {excerpt && parse(excerpt)}
        </ResourceWrapper>
      </Link>
    </StyledResourceHeroCard>
  )
}

export default HeroBlogCard
