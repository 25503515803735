import React from "react"
import {
  StyledBlogList,
  BlogListItem,
} from "../styledComponents/blogArchive"
import HeroBlogCard from "./BlogCards/HeroBlogCard"
import PrimaryBlogCard from "./BlogCards/PrimaryBlogCard"
import SecondaryBlogCard from "./BlogCards/SecondaryBlogCard"
import TertiaryBlogCard from "./BlogCards/TertiaryBlogCard"

const BlogList = ({ posts, pageType }) => {
  return (
    <StyledBlogList pageType={pageType}>
      {posts.map((post, index) => {
        const definePostType = index => {
          if (index === 0) {
            return "hero"
          } else if (index >= 1 && index <= 4) {
            return "primary"
          } else if (index >= 5 && index <= 12) {
            return "secondary"
          } else {
            return "tertiary"
          }
        }
        const title = post.title
        const postType = definePostType(index)

        return (
          <>
            <BlogListItem key={post.uri} className={postType}>
              {postType === "hero" && (
                <HeroBlogCard
                  postType={postType}
                  featuredImage={post.featuredImage}
                  title={title}
                  excerpt={postType === "hero" && post.excerpt}
                  uri={post.uri}
                  postDate={post.date}
                />
              )}

              {postType === "primary" && (
                <PrimaryBlogCard
                  featuredImage={post.featuredImage}
                  title={title}
                  uri={post.uri}
                  postDate={post.date}
                />
              )}

              {postType === "secondary" && (
                <SecondaryBlogCard
                  featuredImage={post.featuredImage}
                  title={title}
                  uri={post.uri}
                  postDate={post.date}
                />
              )}

              {postType === "tertiary" && (
                <TertiaryBlogCard
                  title={title}
                  uri={post.uri}
                  postDate={post.date}
                />
              )}
            </BlogListItem>
          </>
        )
      })}
    </StyledBlogList>
  )
}

export default BlogList
