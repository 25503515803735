import React from "react"
import StyledSocialLinks from "../styledComponents/socialLinks"
import Icon from "./icon"
import analyticClickEvent from "../components/googleAnalytics"

const ShareTools = ( {shareTitle, pageLocation, page} ) => {
  const gaLabelLinkedIn = 'Share : LinkedIn : ' + page
  const gaLabelTwitter = 'Share : Twitter : ' + page
  return (
    <StyledSocialLinks>
      <span>{shareTitle}</span>
      <ul>
      <li key="facebook">
          <a
            onClick={() => analyticClickEvent(gaLabelLinkedIn)}
            href={
              pageLocation
                ? `https://www.facebook.com/sharer/sharer.php?u==${pageLocation}`
                : `https://www.facebook.com/kaarmibeing`
            }
          >
            <Icon shareTitle={shareTitle} name="facebook" />
          </a>
        </li>
        {/* <li key="linkedin">
          <a
            onClick={() => analyticClickEvent(gaLabelLinkedIn)}
            href={
              pageLocation
                ? `https://www.linkedin.com/sharing/share-offsite/?url=${pageLocation}`
                : `https://www.linkedin.com/company/pharmaspectra/`
            }
          >
            <Icon shareTitle={shareTitle} name="linkedin" />
          </a>
        </li>
        <li key="instagram">
          <a
            onClick={() => analyticClickEvent(gaLabelTwitter)}
            href={
              pageLocation
                ? `https://instagram.com/intent/tweet?url=${pageLocation}`
                : `https://instagram.com/pharmaspectra?lang=en`
            }
          >
            <Icon shareTitle={shareTitle} name="instagram" />
          </a>
        </li> */}
      </ul>
    </StyledSocialLinks>
  )
}
export default ShareTools
