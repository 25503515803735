import styled, { css } from "styled-components"
import breakpoints from "./breakpoints"
import { regular12, semibold12 } from "./typography"
import { baseGridStyles } from "./base";
import { sectionHeaderFontIncrease } from "./base"
const StyledBlogSectionWrapper = styled.section`
 background: var(--grey-lightest);
`
const StyledBlogHeader = styled.header`
  ${baseGridStyles}
  text-align: center;
  margin-top: 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 2.4rem;
    text-align: left;
    padding-top: 2.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-top: 3.2rem;
    padding-top: 3.2rem;
  }
  h1 {
    grid-column: span 4;
  }
  h2 {
    ${sectionHeaderFontIncrease}
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 1/5;
      margin-left: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column: 1/7;
    }
  }
  div {
    grid-column: span 4;
    justify-content: center;
    /* margin-top: -1.4rem; */
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 5/7;
      align-items: center;
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 11/13;
      margin-right: 0;
    }
  }
`

const StyledBlogList = styled.ol`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  ${baseGridStyles}
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto;
  }

  a,
  a:hover,
  a:focus {
    border-bottom: 0;
  }

  /* Overrides */
  // Horizontal Seperator line between Hero and primary cards
  & li:nth-child(1) {
    p {
      @media screen and (min-width: ${breakpoints.sm}px) {
        border-bottom: 0;
      }
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      position: relative;
      padding-bottom: ${props => props.pageType ? "0;" : "1.6rem;"};
      &:after {
        content: " ";
        display: block;
        width: calc((976 / 1024) * 100vw);
        border-bottom: ${props => props.pageType ? "0;" : "solid 1px var(--light-blue-mid);"};
        bottom: 0;
        left: 0;
        position: absolute;
        max-width: 130.4rem;
      }
    }
  }

  // Horizontal Seperator line between primary and secondary cards
  & li:nth-child(5) {
    position: relative;
    border-bottom: ${props => props.pageType ? "0;" : "solid 1px var(--light-blue-mid);"};
    padding-bottom: ${props => props.pageType ? "0;" : "1.6rem"};
    @media screen and (min-width: ${breakpoints.sm}px) {
      border-bottom: 0;
    }
  }

  // Horizontal Seperator line between secondary and tertiary cards
  & li:nth-child(4) {
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding-bottom: ${props => props.pageType ? "0;" : "1.6rem"};
      position: relative;
      &:after {
        content: " ";
        display: block;
        width: calc((672 / 720) * 100vw);
        border-bottom: ${props => props.pageType ? "0;" : "solid 1px var(--light-blue-mid);"};
        bottom: 0;
        left: 0;
        position: absolute;
        
      }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      &:after {
        border-bottom: 0;
        content: none;
      }
    }
  }

  // Horizontal Seperator line between Hero/Primary and Secondary cards
  & li:nth-child(10) {
    @media screen and (min-width: ${breakpoints.l}px) {
      position: relative;
      border-bottom: 0;
      &:after {
        content: " ";
        display: block;
        width: 130.4rem;
        border-bottom: solid 1px var(--light-blue-mid);
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }
  }

  & li:nth-child(12) {
    @media screen and (min-width: ${breakpoints.sm}px) {
      border-bottom: 0;
      position: relative;
      
      &:after {
        content: " ";
        display: block;
        width: calc((672 / 720) * 100vw);
        border-bottom: solid 1px var(--light-blue-mid);
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      &:after {
          width: calc((976 / 1024) * 100vw);
      }
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      border-bottom: 0;
      &:after {
          display: none;
      }
    }
  }

  & li:nth-child(13) {
    position: relative;
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 1.6rem;
    border-bottom: solid 1px var(--light-blue-mid);

    @media screen and (min-width: ${breakpoints.sm}px) {
      border-bottom: 0;
    }
    article {
      padding-bottom: 0;
    }
  }
`

const heroListItemStyles = css`
  position: relative;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
    border-bottom: solid 1px var(--light-blue-mid);
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-row: span 2;
    grid-column: span 6;
    border-bottom: 0;
  }
`

const primaryListItemStyles = css`
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
    padding: 0;
  }
`

const secondaryListItemStyles = css`
  border-bottom: dotted 1px var(--light-blue-mid);
  padding-bottom: 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
    margin: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 4;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: span 3;
  }
`

const tertiaryListItemStyles = css`
  margin: 0;
  padding-bottom: 1.6rem;
  border-bottom: dotted 1px var(--light-blue-mid);
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 2;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 4;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: span 3;
  }
`

const BlogListItem = styled.li`
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 6;
    padding-bottom: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
  }

  a:hover h1,
  a:hover h2,
  a:hover h3,
  a:hover h4 {
    color: var(--light-blue-dark);
  }
  &.hero {
    ${heroListItemStyles}
  }

  &.primary {
    ${primaryListItemStyles}
  }

  &.secondary {
    ${secondaryListItemStyles}
  }

  &.tertiary {
    ${tertiaryListItemStyles}
  }

  &.secondary + &.tertiary {
    margin-top: 0; 
  }

  /* force the first Tertiary Item to start a new line */
  &.secondary + &.tertiary {
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column-start: 1;
      grid-column-end: 5;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`

const BlogPagination = styled.div`
  display: none;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
  margin-bottom: 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-bottom: 2.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-bottom: 3.2rem;
  }


  .previousPageButton,
  .nextPageButton {
    display: block;
    padding: 0 1.6rem;
    border-bottom: 0;
    svg path {
      fill: var(--light-blue-darkest);
    }
    &:hover svg path,
    &:focus svg path {
      fill: var(--light-blue-dark);
    }
  }

  span.previousPageButton,
  span.nextPageButton {
    svg path,
    &:hover svg path,
    &:focus svg path {
      fill: var(--grey-mid);
    }
  }
  .nextPageButton {
    transform: rotate(180deg);
  }
`

const CurrentPageNumber = styled.span`
  ${semibold12}
  display: block;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  padding-top: 0.2rem;
  border: solid 1px var(--grey-mid);
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: none;
  }
`

const PaginationPageNumber = styled.li`
  ${props => (props.isCurrentPage ? semibold12 : regular12)};
  display: block;
  text-align: center;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    border: ${props => (props.isCurrentPage ? "solid 1px var(--grey-mid-dark)" : "solid 1px var(--light-blue-darkest)")};
    color: ${props => (props.isCurrentPage ? "var(--grey-mid-dark)" : "var(--light-blue-darkest)")};
    text-decoration: none;
    text-align: center;
    padding-top: 0.2rem;
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    

    &:visited {
      border: ${props => (props.isCurrentPage ? "solid 1px var(--grey-mid-dark)" : "solid 1px var(--light-blue-darkest)")};
    }

    &:hover,
    &:focus {
      color: var(--light-blue-dark);
      border: solid 1px var(--light-blue-dark);
    }
  }
`

const PaginationList = styled.ol`
  display: none;
  padding: 0;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: flex;
    list-style: none;
    margin: 0;
    align-items: center;
  }
`

export {
  StyledBlogSectionWrapper,
  StyledBlogHeader,
  StyledBlogList,
  BlogListItem,
  BlogPagination,
  CurrentPageNumber,
  PaginationPageNumber,
  PaginationList,
}
