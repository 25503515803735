import styled from "styled-components"
import { bold18 } from "../../styledComponents/typography"
import breakpoints from "../../styledComponents/breakpoints"

const StyledResourcePrimaryCard = styled.article`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;

  p {
    margin: 0;
    padding-bottom: 1.6rem;
  }
`

const ResourceHeader = styled.h2`
  color: var(--grey-darker);
  margin: 0.8rem 0 0;
  ${bold18}
  color: var(--light-blue-darkest);
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
  }
  

  a {
    text-decoration: none;
  }
`

const ResourceFigure = styled.figure`
  margin: 0;
  padding: 0;
  &:first-child {
    margin-left: -1.6rem;
    width: 100vw;
    min-width: 32rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: 0;
      min-width: auto;
      width: auto;
    }
  }
`

const ResourceFigureCaption = styled.figcaption`
  margin: 0;
  padding: 0;
`

export {
  StyledResourcePrimaryCard,
  ResourceHeader,
  ResourceFigure,
  ResourceFigureCaption,
}
