import styled from "styled-components"
import { regular14 } from "./typography"

const StyledSocialLinks = styled.div`
  margin: 0.8rem 0 0;
  line-height: 1.6rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  @media screen and (min-width: 1300px) {
    margin: 0.8rem auto 0;
  }
  span {
    margin: 0;
    ${regular14};
    line-height: 2.4rem;
    margin-right: 1.6rem;
    color: var(--grey-mid-dark);
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    height: 2.4rem;
  }
  li {
    margin-right: 1.6rem;
    display: inline-block;

    &:last-child{
      margin-right: 0;
    }
  }
  a {
    border-bottom: 0;
    svg path {
      fill: var(--light-blue-darkest);
    }

    &:hover,
    &:focus {
      border-bottom: 0;
      svg path {
        fill: var(--light-blue-dark);
      }
    }
  }
`

export default StyledSocialLinks
